<template>
  <div class="auth-content">
    <img src="~assets/images/auth/auth-top-bg.png" class="auth-top-bg" alt="" />
    <img src="~assets/images/auth/auth-bot-bg.png" class="auth-bot-bg" alt="" />
    <div style="width: 100%;height: 100%;overflow:auto;position: relative;" v-if="userInfo && userInfo.managed_circle">
      <div class="audit-content">
        <div class="audit">
          <div class="chose-circle-box vertical-center" @click="circlesLink" v-if="circlesData.length > 1">
            <img src="~assets/images/header/chose-circle.png" class="chose-circle-icon chose-circle" alt="" />
            <img src="~assets/images/header/chose-circle-a.png" class="chose-circle-icon chose-circle-act" alt="" />
            <p class="chose-tit">切换小组</p>
          </div>
          <!--  编辑按钮-->
          <!--        <img src="~assets/images/profile/edit.png" class="edit-icon" @click="changeEdit(true)" alt="">-->
          <div class="edit-icon flex-nowrap">
            已有账号，
            <div @click="quitLogin">
              <span class="blue">快捷登录</span>
            </div>
          </div>
          <div class="seat"></div>
          <el-divider></el-divider>
          <div class="align-center">
            <img src="~assets/images/auth/examine.png" class="audit-img" />
          </div>

          <div v-if="userInfo && userInfo.managed_circle && userInfo.managed_circle.status == 0">
            <div class="examine-text">审核中...</div>
            <div class="sub">您的信息已提交，我们将于48小时内审核完成，请等待审核...</div>
          </div>
          <div v-if="userInfo && userInfo.managed_circle && userInfo.managed_circle.status == -1">
            <div class="examine-text" style="color: #FFA509;">审核未通过</div>
            <div class="sub">{{ userInfo.managed_circle.error_messages }}</div>
          </div>
        </div>
        <div class="align-center">
          <el-button class="examine-btn" @click="showDetails" round>查看信息</el-button>
        </div>
      </div>
      <div class="bottom-hint-box align-center">
        {{ $static.footer_text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Perfect',
  data() {
    return {
      circlesData: [],
    };
  },
  created() {
    this.getCircles();
    if (this.userInfo && this.userInfo.managed_circle && this.userInfo.managed_circle.status == 1) {
      this.$router.replace('/home');
    }
  },
  watch: {
    userInfo() {
      if (this.userInfo && this.userInfo.managed_circle && this.userInfo.managed_circle.status == 1) {
        this.$router.replace('/home');
      }
    },
  },
  methods: {
    // 查看详情
    showDetails() {
      this.$router.push('/perfect');
    },
    quitLogin() {
      this.$cache.removeLoginInfo();
      this.$router.push('/login');
    },
    // 获取小组列表
    getCircles() {
      let url = this.$api.userManagedCircles;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          let _data = res.data.data;
          this.circlesData = _data;
          if (_data && _data.length) {
            if (!localStorage.getItem('circle-uuid')) {
              localStorage.setItem('circle-uuid', _data[_data.length - 1].uuid);
            }
            this.$user.getUserInfo();
          }
        }
      });
    },
    // 切换小组
    circlesLink() {
      this.$router.push('/verify');
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/auth/auth.less';
@import '~assets/less/auth/audit.less';
.auth-content {
  overflow: hidden;
}
.audit-content {
  margin: 5% auto 0 auto;
}
.chose-circle-box {
  position: absolute;
  left: 20px;
  top: 24px;
  z-index: 1;
  cursor: pointer;
  .chose-circle-icon {
    width: 16px;
    height: 18px;
    display: none;
  }
  .chose-circle {
    display: block;
  }
  .chose-tit {
    margin-left: 10px;
    color: #93999f;
    font-size: 14px;
  }
}
.chose-circle-box:hover {
  .chose-circle {
    display: none;
  }
  .chose-circle-act {
    display: block;
  }
  .chose-tit {
    color: #81d8d0;
  }
}
</style>
